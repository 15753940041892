import dayjs from 'dayjs';
import { DocumentData, DocumentReference, FirestoreDataConverter, QueryDocumentSnapshot, Timestamp, doc } from 'firebase/firestore';
import { Customer } from '../App/data/customers';
import { firestore } from './firebase';
import { BankSettlement } from './BankSettlement';

// Toby: override the document reference wrapper
export type BankForm = Omit<BankEntry, 'customer' | 'dateOfBankEntry' | 'bankSettlementRef'> & {
  customer: string;
  _id: string;
  dateOfBankEntry: string;
  issueDate: string;
  files: File[];

  bankTransactionID: string;

  bankSettlementRef: string;
};

export interface BankEntry extends DocumentData {
  entryType: 'Bank Payable' | 'Bank Receivable';

  paymentType: 'Bank' | 'Telegraphic Transfer' | 'Cheque' | 'Cash' | 'WCA Partner Pay';
  remarks: string;

  customer?: DocumentReference<Customer>;
  // Toby: Firebase function is responsible for populating the _customer field
  _customer: Readonly<Customer>;

  currency: 'HKD' | 'USD';
  amount: number;
  extraCharges: number;

  // date of received or sent
  // date of bank transaction
  dateOfBankEntry: Timestamp;
  bankTransactionID: string;

  // Date of creation of this entry
  issueDate: Timestamp;

  // paths of the related files in firebase storage
  relatedFiles: string[];

  bankSettlementRef: DocumentReference<BankSettlement> | null;
}

export const bankEntryConverter = {
  toFirestore: (data: BankForm) => {
    const { customer, dateOfBankEntry, _id, files, issueDate, ...rest } = data;
    return {
      ...rest,
      issueDate: dayjs(issueDate).toDate(),
      dateOfBankEntry: dayjs(dateOfBankEntry).toDate(),
      customer: customer ? doc(firestore, customer) : undefined,

      bankSettlementRef: data.bankSettlementRef ? doc(firestore, data.bankSettlementRef) : null,
    };
  },
  fromFirestore: (snap: QueryDocumentSnapshot<BankEntry>) => {
    const data = snap.data();

    return {
      ...data,
      issueDate: dayjs(data.issueDate?.toDate()).format('YYYY-MM-DD'),
      customer: data.customer?.path,
      dateOfBankEntry: dayjs(data.dateOfBankEntry.toDate()).format('YYYY-MM-DD'),
      _id: snap.ref.id,
      files: [],
      bankSettlementRef: data.bankSettlementRef?.path ?? null,
    } as BankForm;
  },
};
