import { DocumentReference, QueryDocumentSnapshot, doc } from 'firebase/firestore';
import { firestore } from '../../utils/firebase';
import { Charge } from './charge';
import { BankSettlement } from '../../utils/BankSettlement';

export interface Invoice {
  invoice_id: string;
  chargeCode: string;
  type: string;
  chargeDescription: string;
  currency: string;
  chargeRate: number;
  chargeUnit: string;
  chargeMi: string;
  lastUpdate: number | Date;
  lastUser: number | Date;
  partialPayments: {
    partialAmount: number;
    date: string;
    remarks: string;
  }[];
  paymentDate: string;
  paidAmount: number;
  paidRemarks: string;
  status: InvoiceStatus;
  receiver: string;
  address: string;
  timestamp: string;

  total: number;

  mawb: {
    id: string;
  };
  invoice_jobid: string;
  senderInvoiceNumber: string;
  receiverJobNumber: string;
  departure_port: string;
  departure_port_right: string;
  destination_port: string;
  customer: {
    address: string;
    sales: string;
    telephoneNumber: string;
    company_name: string;
    companyNumber: string;
  };

  faxnum: string;
  contact: string;
  terms: string;
  sales: string;
  airline_left: string;
  airline_right: string;

  gross_weight_left: number;
  gross_weight_right: number;

  chargeable_weight_left: number;
  chargeable_weight_right: number;

  vol_weight: number;

  quantity_left: number;
  quantity_right: number;

  hawb: string;

  remarks: string;

  charges: Charge[];

  bankSettlementRef?: DocumentReference<BankSettlement> | null;
}

export enum InvoiceStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  PART_PAID = 'PART_PAID',
}

export interface InvoiceCreateInterface {
  airline_flight_destination: string;
  airline_flight_number: string;
  airline_left: string;
  airline_right: string;
  chargeable_weight_left: string;
  chargeable_weight_right: string;
  charges: Charge[];
  contact: string;
  currency: string;
  customer: {
    address: string;
    company_name: string;
    country: string;
    email: string;
    fax: string;
    sales: string;
    telephoneNumber: string;
    companyNumber: string;
  };
  departure_port: string;
  departure_port_right: string;
  destination_port: string;
  faxNum: string;
  gross_weight_left: string;
  gross_weight_right: string;
  hawb: string;
  invoice_id: string;
  invoice_jobid: string;
  mawb: {
    id: string;
  };
  quantity: number;
  quantity_left: string;
  quantity_right: string;
  remarks: string;
  seller: {
    HKD_account: string;
    USD_account: string;
    WCA_account: string;
    bank_address: string;
    bank_code: string;
    bank_name: string;
    bank_swift: string;
    city_country: string;
    company_address: string;
    company_name: string;
    email: string;
    fax: string;
    staff_name: string;
    telephone: string;
  };
  senderInvoiceNumber: string;
  terms: string;
  timestamp: string;
  total: number;
  vol_weight: string;
  status: InvoiceStatus;

  bankSettlementRef: string | null;
}

export interface InvoiceViewInterface {
  customer_name: string;
}

export const invoiceConverter = {
  toFirestore: (data: InvoiceCreateInterface) => {
    const { bankSettlementRef, ...rest } = data;
    return {
      ...rest,
      // convert DocumentReference to path string
      bankSettlementRef: bankSettlementRef ? doc(firestore, bankSettlementRef) : null,
    };
  },
  fromFirestore: (snap: QueryDocumentSnapshot<Invoice>) => {
    const data = snap.data();

    return {
      ...data,
      // convert DocumentReference to path string
      bankSettlementRef: data.bankSettlementRef?.path ?? null,
    } as any;
  },
};
