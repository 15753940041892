import { DocumentReference, QueryDocumentSnapshot, doc } from 'firebase/firestore';
import { BankSettlement } from '../../utils/BankSettlement';
import { firestore } from '../../utils/firebase';

export type DebitNoteForm = DebitNote & { bankSettlementRef: string | null };

export interface DebitNote {
  timestamp: string;
  from: string;
  currency: string;
  total: number | null;
  mawb: string;
  createdBy: string;
  revise: number;
  status: string;
  items: { name: string; price: number | null; currency: string }[];
  files: File[] | null;

  bankSettlementRef?: DocumentReference<BankSettlement> | null;
}
//   timestamp: dayjs().subtract(12, 'day').toDate(),
//   from: 'ABC Company',
//   currency: 'HKD',
//   total: 1200,
//   mawb: '123-89438158',
//   status: 'Due',
//   createdBy: 'Staff A',

export const debitNoteConverter = {
  toFirestore: (data: DebitNoteForm) => {
    const { bankSettlementRef, ...rest } = data;
    return {
      ...rest,
      // convert DocumentReference to path string
      bankSettlementRef: bankSettlementRef ? doc(firestore, bankSettlementRef) : null,
    };
  },
  fromFirestore: (snap: QueryDocumentSnapshot<DebitNote>) => {
    const data = snap.data();

    return {
      ...data,
      // convert DocumentReference to path string
      bankSettlementRef: data.bankSettlementRef?.path ?? null,
    } as DebitNoteForm;
  },
};
