import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

// Toby: override the document reference wrapper
export type LedgerTypeForm = LedgerTypes;

export interface LedgerTypes extends DocumentData {
  shortCode: string;
  name: string;
  description: string;
}

export const ledgerTypeConverter = {
  toFirestore: (data: LedgerTypeForm) => {
    const { ...rest } = data;
    return {
      ...rest,
    };
  },
  fromFirestore: (snap: QueryDocumentSnapshot<LedgerTypes>) => {
    const data = snap.data();

    return {
      ...data,
      _id: snap.ref.id,
    } as LedgerTypeForm;
  },
};
