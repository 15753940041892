import { DocumentData, DocumentReference, QueryDocumentSnapshot, doc } from 'firebase/firestore';
import { firestore } from '../../utils/firebase';
import { BankSettlement } from '../../utils/BankSettlement';

export type CostingForm = Costing & { bankSettlementRef: string | null };
export interface Costing extends DocumentData {
  sender: string;
  receiver: string;
  senderID: string;
  receiverID: string;
  mawb: string;
  timestamp: Date;
  contactEmail: string;
  items: {
    description: string;
    unit: string;
    unitPrice: number;
    currency: string;
    amount: number;
  }[];
  senderInvoiceNumber: string;
  senderJobNumber: string;
  receiverJobNumber: string;
  total: number;
  currency: string;
  description: string;
  status: CostingStatus;
  _id: string;
  /*
  files: {
    uploadBy: string;
    timestamp: Timestamp;
    fileURL: string;
    description: string;
  }[];
*/
  _attachments: { file: FileList | any; description: string }[] | null;

  files: File[] | null;
  //    _attachments: File[];

  paymentDate: string;
  paidAmount: number;
  paidRemarks: string;
  partialPayments: {
    partialAmount: number;
    date: string;
    remarks: string;
  }[];

  bankSettlementRef?: DocumentReference<BankSettlement> | null;
}

export enum CostingStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  PART_PAID = 'PART_PAID',
}

export const costingConverter = {
  toFirestore: (data: CostingForm) => {
    const { bankSettlementRef, ...rest } = data;
    return {
      ...rest,
      // convert DocumentReference to path string
      bankSettlementRef: bankSettlementRef ? doc(firestore, bankSettlementRef) : null,
    };
  },
  fromFirestore: (snap: QueryDocumentSnapshot<Costing>) => {
    const data = snap.data();

    return {
      ...data,
      // convert DocumentReference to path string
      bankSettlementRef: data.bankSettlementRef?.path ?? null,
    } as CostingForm;
  },
};
