import { DocumentData, DocumentReference, QueryDocumentSnapshot, Timestamp, doc } from 'firebase/firestore';
import { LedgerTypes } from './ledgerTypes';
import { firestore } from './firebase';

// Toby: override the document reference wrapper
export type GeneralLedgerForm = Omit<GeneralLedger, 'name'> & {
  _id: string;
  name: string;
  files: File[];
};

export interface GeneralLedger extends DocumentData {
  // generalLedger ID
  issueDate: Timestamp;
  name: DocumentReference<LedgerTypes>;
  _name: string;
  status: 'New' | 'Approved' | 'Settled';

  paymentType: string;

  currency: 'HKD' | 'USD';
  item: string;
  remarks: string;

  // paths of the related files in firebase storage
  relatedFiles: string[];
}

export const generalLedgerConverter = {
  toFirestore: (data: GeneralLedgerForm) => {
    const { _id, files, type, ...rest } = data;
    return {
      ...rest,

      name: data.name ? doc(firestore, data.name) : null,
    };
  },
  fromFirestore: (snap: QueryDocumentSnapshot<GeneralLedger>) => {
    const { name, ...data } = snap.data();

    return {
      name: name?.path,
      files: [],
      ...data,
      _id: snap.ref.id,
    } as GeneralLedgerForm;
  },
};
