import { DocumentReference, QueryDocumentSnapshot, doc } from 'firebase/firestore';
import { BankSettlement } from '../../utils/BankSettlement';
import { firestore } from '../../utils/firebase';

export type CreditNoteForm = CreditNote & { bankSettlementRef: string | null };
export interface CreditNote {
  timestamp: string;
  from: string;
  currency: string;
  total: number | null;
  mawb: string;
  createdBy: string;
  revise: number;
  status: string;
  files: File[] | null;

  bankSettlementRef?: DocumentReference<BankSettlement> | null;
}

export const creditNoteConverter = {
  toFirestore: (data: CreditNoteForm) => {
    const { bankSettlementRef, ...rest } = data;
    return {
      ...rest,
      // convert DocumentReference to path string
      bankSettlementRef: bankSettlementRef ? doc(firestore, bankSettlementRef) : null,
    };
  },
  fromFirestore: (snap: QueryDocumentSnapshot<CreditNote>) => {
    const data = snap.data();

    return {
      ...data,
      // convert DocumentReference to path string
      bankSettlementRef: data.bankSettlementRef?.path ?? null,
    } as CreditNoteForm;
  },
};
